const StackCards = function (element) {
  this.element = element;
  this.items = this.element.getElementsByClassName('stats-list__item');
  this.scrollingFn = false;
  this.scrolling = false;
  initStackCardsEffect(this);
  initStackCardsResize(this);
};

function initStackCardsEffect(element) {
  setStackCards(element);
  const observer = new IntersectionObserver(stackCardsCallback.bind(element), {threshold: [0, 1]});
  observer.observe(element.element);
}

function initStackCardsResize(element) {
  element.element.addEventListener('resize-stack-cards', function () {
    setStackCards(element);
    animateStackCards.bind(element);
  });
}

function stackCardsCallback(entries) {
  if (entries[0].isIntersecting) {
    if (this.scrollingFn) return;
    stackCardsInitEvent(this);
  } else {
    if (!this.scrollingFn) return;
    window.removeEventListener('scroll', this.scrollingFn);
    this.scrollingFn = false;
  }
}

function stackCardsInitEvent(element) {
  element.scrollingFn = stackCardsScrolling.bind(element);
  window.addEventListener('scroll', element.scrollingFn);
}

function stackCardsScrolling() {
  if (this.scrolling) return;
  this.scrolling = true;
  window.requestAnimationFrame(animateStackCards.bind(this));
}

function setStackCards(element) {
  element.marginY = getComputedStyle(element.element).getPropertyValue('--stack-cards-gap');
  getIntegerFromProperty(element);
  element.elementHeight = element.element.offsetHeight;

  const cardStyle = getComputedStyle(element.items[0]);
  element.cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue('top')));
  element.cardHeight = Math.floor(parseFloat(cardStyle.getPropertyValue('height')));
  element.windowHeight = window.innerHeight;

  if (isNaN(parseInt(element.marginY))) {
    element.element.style.paddingBottom = '0px';
  } else {
    element.element.style.paddingBottom = (element.marginY * (element.items.length - 1)) + 'px';
  }

  for (let i = 0; i < element.items.length; i++) {
    if (isNaN(parseInt(element.marginY))) {
      element.items[i].style.transform = 'none;';
    } else {
      element.items[i].style.transform = 'translateY(' + element.marginY * i + 'px)';
    }
  }
}

function getIntegerFromProperty(element) {
  const node = document.createElement('div');
  node.setAttribute('style', 'opacity:0; visibility: hidden; position: absolute; height:' + element.marginY);
  element.element.appendChild(node);
  element.marginY = parseInt(getComputedStyle(node).getPropertyValue('height'));
  element.element.removeChild(node);
}

function animateStackCards() {
  if (isNaN(this.marginY)) {
    this.scrolling = false;
    return;
  }

  const top = this.element.getBoundingClientRect().top;

  if (this.cardTop - top + this.element.windowHeight - this.elementHeight - this.cardHeight + this.marginY + this.marginY * this.items.length > 0) {
    this.scrolling = false;
    return;
  }

  for (let i = 0; i < this.items.length; i++) {
    const scrolling = this.cardTop - top - i * (this.cardHeight + this.marginY);
    if (scrolling > 0) {
      const scaling = i === this.items.length - 1 ? 1 : (this.cardHeight - scrolling * 0.05) / this.cardHeight;
      this.items[i].style.transform = 'translateY(' + this.marginY * i + 'px) scale(' + scaling + ')';
    } else {
      this.items[i].style.transform = 'translateY(' + this.marginY * i + 'px)';
    }
  }

  this.scrolling = false;
}

function osHasReducedMotion() {
  if (!window.matchMedia) return false;
  const matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)');
  if (matchMediaObj) return matchMediaObj.matches;
  return false;
}

function checkInput(input) {
  if (input.value.length === 0) {
    input.classList.remove('has-value');
    return;
  }

  input.classList.add('has-value');
}

document.addEventListener('DOMContentLoaded', () => {
  const stackCards = document.getElementsByClassName('stats-list');
  const intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
  const reducedMotion = osHasReducedMotion();

  if (stackCards.length > 0 && intersectionObserverSupported && !reducedMotion) {
    const stackCardsArray = [];

    for (let i = 0; i < stackCards.length; i++) {
      (function (i) {
        stackCardsArray.push(new StackCards(stackCards[i]));
      })(i);
    }

    let resizingId = false;
    const customEvent = new CustomEvent('resize-stack-cards');

    window.addEventListener('resize', function () {
      clearTimeout(resizingId);
      resizingId = setTimeout(() => {
        for (let i = 0; i < stackCardsArray.length; i++) {
          (function (i) {
            stackCardsArray[i].element.dispatchEvent(customEvent)
          })(i);
        }
      }, 500);
    });
  }

  const header = document.querySelector('header');
  const hamburger = document.querySelector('.hamburger');
  const menu = document.querySelector('.menu');
  const menuItems = menu.querySelectorAll('.menu_item a');

  hamburger.addEventListener('click', (e) => {
    if (e.currentTarget.classList.contains('hamburger--open')) {
      e.currentTarget.classList.remove('hamburger--open');
      menu.classList.remove('menu--open');
      header.classList.remove('header-open');
      document.body.classList.remove('no-scroll');
      return;
    }

    e.currentTarget.classList.add('hamburger--open');
    menu.classList.add('menu--open');
    header.classList.add('header-open');
    document.body.classList.add('no-scroll');
  });

  menuItems.forEach((item) => {
    item.addEventListener('click', () => {
      hamburger.classList.remove('hamburger--open');
      menu.classList.remove('menu--open');
      header.classList.remove('header-open');
      document.body.classList.remove('no-scroll');
    })
  });

  const showFormButtons = document.querySelectorAll('.show-form-button');
  const formWrapper = document.querySelector('.form-wrapper');
  const closeModalBtn = formWrapper.querySelector('.close-form');
  const closeStatusBtns = formWrapper.querySelectorAll('.close-status-button');

  showFormButtons.forEach((btn) => {
    btn.addEventListener('click', () => {
      hamburger.classList.remove('hamburger--open');
      menu.classList.remove('menu--open');
      header.classList.remove('header-open');
      document.body.classList.add('no-scroll');
      formWrapper.classList.add('form-wrapper--modal');
    })
  })

  const closeModal = () => {
    formWrapper.classList.remove('form-wrapper--modal');
    formWrapper.classList.remove('form-wrapper--modal-success');
    formWrapper.classList.remove('form-wrapper--modal-error');
    document.body.classList.remove('no-scroll');
  }

  closeStatusBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      closeModal();
    })
  })

  closeModalBtn.addEventListener('click', () => {
    closeModal();
  })

  const apiUrl = 'https:/videobanking.kz/mail.php'
  const form = document.getElementById('contact-form');
  const inputs = document.querySelectorAll('input');
  const textareas = document.querySelectorAll('textarea');
  const submitBtn = document.querySelector('.submit-button');

  if (!form) {
    alert('Произошла ошибка, попробуйте зайти позже');
    return;
  }

  inputs.forEach((input) => {
    checkInput(input);

    input.addEventListener('input', (e) => {
      checkInput(e.target)
    })
  })

  textareas.forEach((textarea) => {
    checkInput(textarea);

    textarea.addEventListener('input', (e) => {
      checkInput(e.target)
    })
  })

  const handleApiError = () => {
    formWrapper.classList.add('form-wrapper--modal');
    formWrapper.classList.add('form-wrapper--modal-error');
    submitBtn.disabled = false;
  }

  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    if (submitBtn.disabled) return;

    submitBtn.disabled = true;

    const formData = new FormData(e.target);
    const url = new URL(apiUrl);

    for (const [key, value] of formData.entries()) {
      if (!value || typeof value !== 'string') continue;
      url.searchParams.append(key, value);
    }

    try {
      const res = await fetch(url);
      const parsedRes = await res.json();

      if (parsedRes.status === 'Error') {
        handleApiError();
        return;
      }

      formWrapper.classList.add('form-wrapper--modal');
      formWrapper.classList.add('form-wrapper--modal-success');
    } catch (e) {
      console.error(e)
      handleApiError();
      return;
    }

    inputs.forEach((input) => {
      input.value = '';
      checkInput(input);
    });
    textareas.forEach((textarea) => {
      textarea.value = '';
      checkInput(textarea);
    });
    submitBtn.disabled = false;
  })
})
